import { combineReducers } from 'redux'
import Auth from './Auth'
import { IAuthStore } from './Auth/reducers'
import Translations from './Translations'
import { ITranslationsStore } from './Translations/reducers'
import { IFeaturedResultsStore } from './FeaturedResults/reducers'
import FeaturedResults from './FeaturedResults'
import { IContactUsStore } from './ContactUs/reducers'
import ContactUs from './ContactUs'
import { ISelfServiceStore } from './SelfService/reducers'
import SelfService from './SelfService'
import { IUserSettingsStore } from './UserSettings/reducers'
import UserSettings from './UserSettings'
import { IDidYouMeanStore } from './DidYouMean/reducers'
import DidYouMean from './DidYouMean'
import { INewsLetterSubscriptionsStore } from './NewsLetterSubscriptions/reducers'
import NewsLetterSubscriptions from './NewsLetterSubscriptions'

// Use an OR type here if there more than one actions, e.g. IAuthStore |INewStore | ...
export type Store = {
  auth: IAuthStore
  translations: ITranslationsStore
  featuredResults: IFeaturedResultsStore
  contactUs: IContactUsStore
  selfService: ISelfServiceStore
  userSettings: IUserSettingsStore
  didYouMean: IDidYouMeanStore
  newsLetterSubscriptions: INewsLetterSubscriptionsStore
}

export const rootReducer = combineReducers<Store>({
  auth: Auth.reducers,
  translations: Translations.reducers,
  featuredResults: FeaturedResults.reducers,
  contactUs: ContactUs.reducers,
  selfService: SelfService.reducers,
  userSettings: UserSettings.reducers,
  didYouMean: DidYouMean.reducers,
  newsLetterSubscriptions: NewsLetterSubscriptions.reducers
})
