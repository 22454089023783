import { Column } from '@material-table/core'
import {
  SupportedLanguage,
  supportedLanguages
} from 'constants/supportedLanguages'
import { ContactUsTile } from 'models/ContactUsTile'
import { getStylesAdminSettings } from 'styles/admin/AdminSettings'
import {
  findContactUsChild,
  findDefaultFilterComponent
} from 'utils/admin/adminFormUtils'
import AdminTableTooltipClip from '../common/AdminTableTooltipClip'
import AdminTableTooltipTitle from '../common/AdminTableTooltipTitle'
import React from 'react'
import createDOMPurify from 'dompurify'
import * as MuiIcons from '@mui/icons-material'

const DOMPurify = createDOMPurify(window)

/**
 * Generate contact us columns list
 * @param changes AdminItemChange list
 * @returns columns list
 */
export const contactUsTileColumns = (
  data: ContactUsTile[]
): Column<ContactUsTile>[] => {
  const classes = getStylesAdminSettings()
  return [
    {
      title: 'Id',
      field: 'id',
      type: 'string',
      filtering: false,
      cellStyle: {
        maxWidth: 40,
        width: 40
      },
      headerStyle: {
        maxWidth: 40,
        width: 40
      },
      width: 40,
      customSort: (a: ContactUsTile, b: ContactUsTile) =>
        parseInt(a.id) - parseInt(b.id)
    },
    {
      title: 'Language',
      field: 'language',
      filtering: false,
      type: 'string',
      cellStyle: {
        maxWidth: 80
      },
      headerStyle: {
        maxWidth: 80
      },
      width: 80,
      render: (rowData: ContactUsTile) => {
        let allLanguages = true

        const cuChilds = findContactUsChild(rowData, data)
        supportedLanguages.forEach((supportedLanguage: SupportedLanguage) => {
          if (rowData.language !== supportedLanguage.locale) {
            if (
              !cuChilds.find(
                (cuChild: ContactUsTile) =>
                  cuChild.language === supportedLanguage.locale
              )
            ) {
              allLanguages = false
            }
          }
        })

        return (
          <div className={classes.chipContainer}>
            {allLanguages ? (
              <>
                <AdminTableTooltipClip
                  size="small"
                  label={'All'}
                  maxWidth={90}
                />
              </>
            ) : (
              <>
                <AdminTableTooltipClip
                  size="small"
                  label={rowData.language}
                  maxWidth={90}
                />
                {cuChilds.length > 0
                  ? cuChilds.map((cuChild: ContactUsTile, index: number) => (
                      <AdminTableTooltipClip
                        size="small"
                        label={cuChild.language}
                        maxWidth={90}
                        key={index}
                      />
                    ))
                  : ''}
              </>
            )}
          </div>
        )
      }
    },
    {
      title: 'Title',
      field: 'title',
      type: 'string',
      filtering: false,
      render: (rowData: ContactUsTile) => (
        <AdminTableTooltipTitle
          className={classes.rowEllipsis}
          basedOn="words"
          unsafeHTML={`${rowData.draft ? '<i><b>(Draft)</b></i> ' : ''}${
            rowData.title ? DOMPurify.sanitize(rowData.title) : ''
          }`}
          maxLine={4}
        />
      ),
      width: 250,
      cellStyle: {
        maxWidth: 250
      },
      headerStyle: {
        maxWidth: 250
      }
    },
    {
      title: 'Description',
      field: 'description',
      type: 'string',
      render: (rowData: ContactUsTile) => (
        <AdminTableTooltipTitle
          className={classes.rowEllipsis}
          basedOn="words"
          unsafeHTML={
            rowData.description ? DOMPurify.sanitize(rowData.description) : ''
          }
          maxLine={2}
        />
      ),
      filtering: false
    },
    {
      title: 'Url',
      field: 'link',
      type: 'string',
      cellStyle: {
        maxWidth: 250
      },
      headerStyle: {
        maxWidth: 250
      },
      width: 250,
      render: (rowData: ContactUsTile) => (
        <AdminTableTooltipTitle
          className={classes.rowEllipsis}
          basedOn="words"
          text={rowData.link}
          maxLine={1}
        />
      ),
      filtering: false
    },
    {
      title: 'Image',
      field: 'image',
      type: 'string',
      render: (rowData: ContactUsTile) => {
        if (rowData.image) {
          const Icon = MuiIcons[rowData.image as keyof typeof MuiIcons]
          if (Icon) {
            return <Icon style={{ width: '50px', height: '50px' }} />
          } else return ''
        } else return ''
      },
      filtering: false,
      cellStyle: {
        maxWidth: 120
      },
      headerStyle: {
        maxWidth: 120
      },
      width: 120
    },
    {
      title: 'Order',
      field: 'order',
      type: 'numeric',
      align: 'left',
      cellStyle: {
        minWidth: 100
      },
      headerStyle: {
        minWidth: 100
      },
      width: 100,
      render: (rowData: ContactUsTile) => (
        <AdminTableTooltipTitle
          className={classes.rowEllipsis}
          basedOn="words"
          text={rowData.order ? rowData.order.toString() : ''}
          maxLine={1}
        />
      ),
      filtering: false
    },
    {
      title: 'Active',
      field: 'isActive',
      type: 'string',
      render: (rowData: ContactUsTile) => (
        <AdminTableTooltipTitle
          className={classes.rowEllipsis}
          basedOn="words"
          text={rowData.isActive ? 'Yes' : 'No'}
          maxLine={1}
        />
      ),
      filterComponent: findDefaultFilterComponent,
      customFilterAndSearch: (value: string, rowData: ContactUsTile) => {
        if ('yes'.startsWith(value.toLocaleLowerCase()) && rowData.isActive)
          return true

        if ('no'.startsWith(value.toLocaleLowerCase()) && !rowData.isActive)
          return true

        return false
      },
      width: 100,
      cellStyle: {
        maxWidth: 100
      },
      headerStyle: {
        maxWidth: 100
      }
    },
    {
      title: 'Scope',
      field: 'scope',
      type: 'string',
      render: (rowData: ContactUsTile) => (
        <AdminTableTooltipClip
          color="primary"
          label={rowData.scope ? rowData.scope : 'All'}
          variant={'outlined'}
          maxWidth={80}
        />
      ),
      width: 100,
      cellStyle: {
        maxWidth: 100
      },
      headerStyle: {
        maxWidth: 100
      },
      customFilterAndSearch: (value: string, rowData: ContactUsTile) => {
        if (!value) return true

        if (!rowData.scope && 'all'.startsWith(value.toLowerCase())) {
          return true
        } else {
          return rowData.scope
            ? rowData.scope.toLowerCase().startsWith(value.toLowerCase())
            : false
        }
      },
      filterComponent: findDefaultFilterComponent
    }
  ]
}
