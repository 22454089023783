import { AdminItemChange } from 'models/AdminItemChange'
import { AdsResult } from 'models/AdsResult'
import { ContactUsTile } from 'models/ContactUsTile'
import { DidYouMeanItem } from 'models/DidYouMean'
import { FeaturedResult } from 'models/FeaturedResult'
import { INewsLetterSubscription } from 'models/NewsLetterSubsciption'
import { PopupResult } from 'models/PopupResult'
import {
  SingleTranslationResult,
  TranslationResult
} from 'models/TranslationResult'

/**
 * Cleans changes and update table data after deletion for adwords, popups and contact us tiles
 * @param deleteItemList Items to delete
 * @param data Current Table data
 * @param newChanges Current active changes
 */
export const deleteItemsAndUpdateState = (
  deleteItemList: (PopupResult | AdsResult | ContactUsTile | DidYouMeanItem)[],
  data: any[],
  newChanges: AdminItemChange[]
): void => {
  deleteItemList.forEach(
    (oldData: PopupResult | AdsResult | ContactUsTile | DidYouMeanItem) => {
      const changeIndex = newChanges
        .filter((change: AdminItemChange) => change.changeType !== 'delete')
        .map((change: AdminItemChange) => change.id)
        .indexOf(oldData.id)

      if (changeIndex !== -1) {
        newChanges.splice(changeIndex, 1) //dont update item when marked as deleted
      }

      let changeDataIndex = 0
      const changeData = data.find((d: any, i: number) => {
        if (d.id === oldData.id) {
          changeDataIndex = i
          return true
        } else {
          return false
        }
      })
      if (changeData) {
        const changeDataId = changeData.id

        newChanges.push({
          id: changeDataId,
          changeType: 'delete',
          changes: changeData
        })

        data.splice(changeDataIndex, 1)
      }
    }
  )
}

/**
 * Cleans changes and update table data after deletion for featured results and translations
 * @param deleteItem Item to delete
 * @param data Current Table data
 * @param newChanges Current active changes
 */
export const deleteItemAndUpdateState = (
  deleteItem: FeaturedResult | TranslationResult | INewsLetterSubscription,
  data: any[],
  newChanges: AdminItemChange[]
): void => {
  const changeIndex = newChanges
    .filter((change: AdminItemChange) => change.changeType !== 'delete')
    .map((change: AdminItemChange) => change.id)
    .indexOf(deleteItem.id)

  if (changeIndex !== -1) {
    newChanges.splice(changeIndex, 1) //dont update item when marked as deleted
  }

  let changeDataIndex = 0
  const changeData = data.find((d: any, i: number) => {
    if (d.id === deleteItem.id) {
      changeDataIndex = i
      return true
    } else {
      return false
    }
  })
  if (changeData) {
    const changeDataId = changeData.id
    let changeDataTranslations
    if (changeData.translations) {
      changeDataTranslations = changeData.translations.map(
        (t: SingleTranslationResult) => {
          t.key = changeData.key
          return t
        }
      )
    }

    newChanges.push({
      id: changeDataId,
      changeType: 'delete',
      changes: changeDataTranslations ? changeDataTranslations : changeData
    })

    data.splice(changeDataIndex, 1)
  }
}

/**
 * Cleans changes and update table data after update for adwords, popups and contact us tiles
 * @param newDataList Items to update
 * @param data Current Table data
 * @param newChanges Current active changes
 */
export const updateItemsAndUpdateSate = (
  newDataList: (AdsResult | PopupResult | ContactUsTile)[],
  data: any,
  newChanges: AdminItemChange[]
) => {
  newDataList.forEach((newData: AdsResult | PopupResult | ContactUsTile) => {
    newData.draft = true
    //find item index in prevState
    let index = -1
    for (let i = 0; i < data.length; i++) {
      const target = data[i]
      if (
        target &&
        target.id === newData.id &&
        target.language === newData.language
      ) {
        index = i
        break
      }
    }
    if (index === -1) {
      //add new item on top
      data.unshift(newData)
    } else {
      //update item in state
      data[index] = newData
    }

    let changeIndex = -1
    for (let i = 0; i < newChanges.length; i++) {
      const target = newChanges[i]
      if (
        target &&
        target.id === newData.id &&
        (target.changes as AdsResult | PopupResult | ContactUsTile).language ===
          newData.language
      ) {
        changeIndex = i
        break
      }
    }

    //set type add, if not exist in prevState, else update
    const newChange = {
      id: index === -1 ? data[0].id : data[index].id,
      changeType: index === -1 ? 'add' : 'update',
      changes: index === -1 ? data[0] : data[index]
    }

    if (changeIndex === -1) {
      //add change item
      newChanges.push(newChange)
    } else {
      //update item in change list
      newChanges[changeIndex] = {
        ...newChange,
        changeType: newChanges[changeIndex].changeType
      }
    }
  })
}

/**
 * Cleans changes and update table data after update for did you mean
 * @param newDataList Items to update
 * @param data Current Table data
 * @param newChanges Current active changes
 */
export const updateDidYouMeanItemsAndUpdateSate = (
  newDataList: DidYouMeanItem[],
  data: any,
  newChanges: AdminItemChange[]
) => {
  newDataList.forEach((newData: DidYouMeanItem) => {
    newData.draft = true
    //find item index in prevState
    let index = -1
    for (let i = 0; i < data.length; i++) {
      const target = data[i]
      if (target && target.id === newData.id) {
        index = i
        break
      }
    }
    if (index === -1) {
      //add new item on top
      data.unshift(newData)
    } else {
      //update item in state
      data[index] = newData
    }

    let changeIndex = -1
    for (let i = 0; i < newChanges.length; i++) {
      const target = newChanges[i]
      if (
        target &&
        target.id === newData.id &&
        (target.changes as DidYouMeanItem).term === newData.term
      ) {
        changeIndex = i
        break
      }
    }

    //set type add, if not exist in prevState, else update
    const newChange = {
      id: index === -1 ? data[0].id : data[index].id,
      changeType: index === -1 ? 'add' : 'update',
      changes: index === -1 ? data[0] : data[index]
    }

    if (changeIndex === -1) {
      //add change item
      newChanges.push(newChange)
    } else {
      //update item in change list
      newChanges[changeIndex] = {
        ...newChange,
        changeType: newChanges[changeIndex].changeType
      }
    }
  })
}

/**
 * Clean draft status for all active changes after publish for adwords, popups and contact us tiles
 * @param changes Current active changes
 * @param data Current table data
 */
export const cleanDraftStatusAfterPublishAll = (
  changes: AdminItemChange[],
  data: any
) => {
  changes.forEach((change: AdminItemChange) => {
    const changeItem = change.changes as AdsResult | PopupResult | ContactUsTile
    //find item index in prevState
    let index = -1
    for (let i = 0; i < data.length; i++) {
      const target = data[i]

      if (
        target &&
        target.id === changeItem.id &&
        target.language === changeItem.language
      ) {
        index = i
        break
      }
    }

    if (index > -1) {
      //update item in state
      const popup = data[index]
      popup.draft = false
      if (popup.isNewItem) {
        delete popup.isNewItem
      }
      data[index] = popup
    }
  })
}

/**
 * Clean draft status for all active changes after publish for did you mean
 * @param changes Current active changes
 * @param data Current table data
 */
export const cleanDraftStatusAfterPublishAllDym = (
  changes: AdminItemChange[],
  data: any
) => {
  changes.forEach((change: AdminItemChange) => {
    const changeItem = change.changes as DidYouMeanItem
    //find item index in prevState
    let index = -1
    for (let i = 0; i < data.length; i++) {
      const target = data[i]

      if (
        target &&
        target.id === changeItem.id &&
        target.term === changeItem.term
      ) {
        index = i
        break
      }
    }
    if (index > -1) {
      //update item in state
      const popup = data[index]
      popup.draft = false
      data[index] = popup
    }
  })
}

/**
 * Clean draft status for all active changes after publish for subscriptions
 * @param changes Current active changes
 * @param data Current table data
 */
export const cleanDraftStatusAfterPublishAllSubsciptions = (
  changes: AdminItemChange[],
  data: any
) => {
  changes.forEach((change: AdminItemChange) => {
    const changeItem = change.changes as INewsLetterSubscription
    //find item index in prevState
    let index = -1
    for (let i = 0; i < data.length; i++) {
      const target = data[i]

      if (
        target &&
        target.id === changeItem.id &&
        target.upn === changeItem.upn
      ) {
        index = i
        break
      }
    }
    if (index > -1) {
      //update item in state
      const popup = data[index]
      popup.draft = false
      data[index] = popup
    }
  })
}

/**
 * Clean draft status for all given changes after publish of a single item for subscriptions
 * @param handleList Changed items
 * @param data Current table data
 */
export const cleanDraftStatusAfterPublishSubscription = (
  handleList: INewsLetterSubscription[],
  data: any
) => {
  handleList.forEach((publishedItem: INewsLetterSubscription) => {
    //find item index in prevState
    let index = -1
    for (let i = 0; i < data.length; i++) {
      const target = data[i]
      if (target && target.id === publishedItem.id) {
        index = i
        break
      }
    }
    if (index > -1) {
      //update item in state
      const ad = data[index] as DidYouMeanItem
      ad.draft = false
      data[index] = ad
    }
  })
}

/**
 * Clean draft status for all given changes after publish of a single item for adwords, popups and contact us tiles
 * @param handleList Changed items
 * @param data Current table data
 */
export const cleanDraftStatusAfterPublish = (
  handleList: (PopupResult | AdsResult | ContactUsTile)[],
  data: any
) => {
  handleList.forEach(
    (publishedItem: PopupResult | AdsResult | ContactUsTile) => {
      //find item index in prevState
      let index = -1
      for (let i = 0; i < data.length; i++) {
        const target = data[i]
        if (
          target &&
          target.id === publishedItem.id &&
          target.language === publishedItem.language
        ) {
          index = i
          break
        }
      }
      if (index > -1) {
        //update item in state
        const ad = data[index] as PopupResult | AdsResult | ContactUsTile
        ad.draft = false
        data[index] = ad
      }
    }
  )
}

/**
 * Clean draft status for all given changes after publish of a single item for did you mean
 * @param handleList Changed items
 * @param data Current table data
 */
export const cleanDraftStatusAfterPublishDym = (
  handleList: DidYouMeanItem[],
  data: any
) => {
  handleList.forEach((publishedItem: DidYouMeanItem) => {
    //find item index in prevState
    let index = -1
    for (let i = 0; i < data.length; i++) {
      const target = data[i]
      if (
        target &&
        target.id === publishedItem.id &&
        target.term === publishedItem.term
      ) {
        index = i
        break
      }
    }
    if (index > -1) {
      //update item in state
      const ad = data[index] as DidYouMeanItem
      ad.draft = false
      data[index] = ad
    }
  })
}
