import React, { Dispatch, useEffect } from 'react'
import Button from '@mui/material/Button'
import {
  CircularProgress,
  Box,
  FormControlLabel,
  Checkbox,
  TextField,
  FormHelperText
} from '@mui/material'
import TranslationsStore from 'store/Translations'
import { Store } from 'store'
import { connect } from 'react-redux'
import { getStylesAdImageUpload } from 'styles/admin/AdImageUpload'
import { AdsResult } from '../../../models/AdsResult'
import { PopupResult } from '../../../models/PopupResult'
import { useNonInitialEffect } from 'utils/useNonInitialEffect'
import createDOMPurify from 'dompurify'
import { IErrorObject } from 'models/FormError'
import { fileTypeFromBuffer } from 'file-type'
import { maximumAllowedFileSize } from 'constants/constants'

interface AdImageUploadProps {
  editProps: AdsResult | PopupResult
  onchange?: () => void
  error?: IErrorObject
}

type AllAdminImageUploadProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  AdImageUploadProps

function AdminImageUpload(props: AllAdminImageUploadProps): JSX.Element {
  const { editProps, upsertImage, upsertImageUrl, onchange, error } = props
  const [lockUpload, setLockupload] = React.useState(false)
  const [isUploadingImage, setIsUploadingImage] = React.useState(false)
  const [overWriteImage, setOverWriteImage] = React.useState(false)
  const [uploadCheckError, setUploadCheckError] = React.useState('')
  const [imageUrl, setImageUrl] = React.useState<string>(editProps.image)

  const classes = getStylesAdImageUpload()
  const DOMPurify = createDOMPurify(window)
  useNonInitialEffect(() => {
    if (onchange) onchange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageUrl])

  useEffect(() => {
    if (
      !upsertImageUrl ||
      (upsertImageUrl.translationId && upsertImageUrl.url)
    ) {
      setLockupload(false)
      setIsUploadingImage(false)

      if (
        upsertImageUrl &&
        upsertImageUrl.translationId === editProps.id &&
        upsertImageUrl.url
      ) {
        editProps.image = upsertImageUrl.url
        editProps.draft = true
        setImageUrl(upsertImageUrl.url)
      }
    }

    if (upsertImageUrl && upsertImageUrl.translationId && !upsertImageUrl.url) {
      setLockupload(true)
      if (upsertImageUrl.translationId === editProps.id)
        setIsUploadingImage(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upsertImageUrl])

  useEffect(() => {
    setIsUploadingImage(false)
    setLockupload(false)
    setOverWriteImage(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const validateUploadedFile = async (file: Blob) => {
    const buffer = await file.arrayBuffer()
    const realFileType = await fileTypeFromBuffer(new Uint8Array(buffer))

    if (
      !realFileType ||
      !realFileType.mime.startsWith('image') ||
      (realFileType.ext !== 'jpg' && realFileType.ext !== 'png')
    ) {
      setUploadCheckError(
        'Wrong file format. Only jpg or png images are allowed.'
      )
      return false
    }

    if (
      file &&
      file.size &&
      file.size > 0 &&
      file.size > maximumAllowedFileSize
    ) {
      setUploadCheckError(
        'The file size is too big. Only images with a size less than 0.25MB are allowed'
      )
      return false
    }

    setUploadCheckError('')
    return true
  }

  const filterFileName = (filename: string): string => {
    return filename.replace(/[/\\?%*:|"<>]/g, '').replace(/\s+/g, '')
  }

  return (
    <>
      <TextField
        variant="standard"
        id="image-url"
        label="Image"
        value={imageUrl || null}
        onChange={(event: any) => {
          const purifiedValue = DOMPurify.sanitize(event.target.value)
          editProps.image = purifiedValue
          setImageUrl(purifiedValue)
        }}
        disabled={lockUpload}
        error={error && error.error ? error.error : false}
        helperText={error && error.error ? error.helperText : ''}
        InputLabelProps={{
          shrink: true
        }}
      />
      <Box className={classes.imageUploadButtonContainer}>
        <Button
          variant="contained"
          component="label"
          disabled={lockUpload}
          className={classes.imageUploadButton}
          style={uploadCheckError ? { border: '1px solid red' } : {}}
        >
          <Box>Upload</Box>

          {isUploadingImage && (
            <Box
              style={{ paddingLeft: '1em', top: '2px', position: 'relative' }}
            >
              <CircularProgress size={14} />
            </Box>
          )}

          <input
            type="file"
            hidden
            onChange={(event: any) => {
              const file = event.target.files[0]
              if (file) {
                const fr = new FileReader()
                fr.onload = async () => {
                  let value = fr.result as string
                  if (value && file.name) {
                    if (await validateUploadedFile(file)) {
                      value = value.substr(5)
                      upsertImage(
                        filterFileName(file.name),
                        value,
                        editProps.id,
                        overWriteImage
                      )
                    }
                  }
                }
                fr.readAsDataURL(file)
              }
            }}
            onClick={(event: any) => {
              event.target.value = null
            }}
          />
        </Button>
        <FormControlLabel
          control={
            <Checkbox
              checked={overWriteImage}
              onChange={(ev) => setOverWriteImage(ev.target.checked)}
              name="overWriteImage"
              color="primary"
            />
          }
          label="Overwrite"
          className={classes.imageOverwriteCheckBox}
        />
      </Box>
      {uploadCheckError && (
        <FormHelperText
          style={{ color: '#f44336', margin: '0 14px 14px 14px' }}
          className={classes.errorRadioBoxHelpText}
        >
          {uploadCheckError}
        </FormHelperText>
      )}
    </>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    upsertImageUrl: TranslationsStore.selectors.upsertImageUrl(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    upsertImage: (
      fileName: string,
      fileContent: string,
      translationId: string,
      overWriteImage: boolean
    ) =>
      dispatch(
        TranslationsStore.actions.upsertImage(
          fileName,
          fileContent,
          translationId,
          overWriteImage
        )
      )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminImageUpload)
