import { Action, AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from '@reduxjs/toolkit'
import { Store } from '..'
import * as Config from '../../config'
import AuthStore from '../Auth'
import { trackException, trackSelfServiceEvent } from 'utils/tracking'
import { IDeputy, IUserSetting } from 'models/UserSettings'
import { ISelfServiceRequest, RequestStatus } from 'models/SelfServiceRequest'
import {
  getGraphAuthToken,
  renewAuthorizationToken
} from 'authentication/token'
import { ISelfServiceDeputyUpdateEvent } from 'models/SelfServiceTrackingEvent'

export enum UserSettingsActionTypes {
  FETCH_USERSETTINGS_REQUEST = 'userSettings/FETCH_USERSETTINGS_REQUEST',
  FETCH_USERSETTINGS_SUCCESS = 'userSettings/FETCH_USERSETTINGS_SUCCESS',
  FETCH_USERSETTINGS_NOTFOUND = 'userSettings/FETCH_USERSETTINGS_NOTFOUND',
  FETCH_USERSETTINGS_FAILURE = 'userSettings/FETCH_USERSETTINGS_FAILURE',
  UPSERT_USERSETTINGS_SUCCESS = 'userSettings/UPSERT_USERSETTINGS_SUCCESS'
}

export type IFetchUserSettingsFailure = Action<UserSettingsActionTypes>
export type IFetchUserSettingsNotFound = Action<UserSettingsActionTypes>

export interface IFetchUserSettingsRequest
  extends Action<UserSettingsActionTypes> {
  payload: {
    upn: string
    displayName: string
  }
}

export interface IFetchUserSettingsSuccess
  extends Action<UserSettingsActionTypes> {
  payload: {
    response: any
    pictureUrl: string
  }
}

export interface IUpsertUserSettingsSuccess
  extends Action<UserSettingsActionTypes> {
  payload: {
    upSertUserSettingsResponse: any
  }
}

export const fetchUserSettingsRequest = (
  upn: string,
  displayName: string
): IFetchUserSettingsRequest => ({
  type: UserSettingsActionTypes.FETCH_USERSETTINGS_REQUEST,
  payload: {
    upn: upn,
    displayName: displayName
  }
})

export const fetchUserSettingsSuccess = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  response: any,
  pictureUrl: string
): IFetchUserSettingsSuccess => ({
  type: UserSettingsActionTypes.FETCH_USERSETTINGS_SUCCESS,
  payload: { response, pictureUrl }
})

export const fetchUserSettingsNotFound = (): IFetchUserSettingsNotFound => ({
  type: UserSettingsActionTypes.FETCH_USERSETTINGS_NOTFOUND
})

export const fetchUserSettingsFailure = (): IFetchUserSettingsFailure => ({
  type: UserSettingsActionTypes.FETCH_USERSETTINGS_FAILURE
})

export const UpSertSetUserSettingsSuccess = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  upSertUserSettingsResponse: any
): IUpsertUserSettingsSuccess => ({
  type: UserSettingsActionTypes.UPSERT_USERSETTINGS_SUCCESS,
  payload: {
    upSertUserSettingsResponse: upSertUserSettingsResponse
  }
})

export const fetchUserSettings = (
  userUpn: string,
  userDisplayName: string
  // eslint-disable-next-line @typescript-eslint/ban-types
): ThunkAction<Promise<void>, Store, {}, AnyAction> => {
  return async (
    // eslint-disable-next-line @typescript-eslint/ban-types
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => Store
  ) => {
    dispatch(fetchUserSettingsRequest(userUpn, userDisplayName))

    // Get and check authentication token
    const aadInfo = AuthStore.selectors.getAADInfo(getState())
    const esToken = await renewAuthorizationToken(
      aadInfo.accessToken,
      aadInfo.instance,
      aadInfo.accounts
    )
    if (esToken !== aadInfo.accessToken) {
      AuthStore.actions.setAuthToken(esToken)
    }
    if (esToken === '') {
      return
    }

    try {
      const apiUrl = `${Config.APIM_BASE_URL}usersettingsapi/getusersettings?upn=${userUpn}&scope=ESSettings`

      const data = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })

      if (data.status !== 200) {
        dispatch(fetchUserSettingsFailure())
        trackException(
          'Error in fetching user settings',
          new Error('Error in data object after fetching user settings')
        )
        return
      }

      const result = await data.json()

      const count = result._count

      if (count !== 1) {
        dispatch(fetchUserSettingsNotFound())
        return
      }

      const userSettingResult = result.Documents[0]

      // Set deputies if not present
      if (!userSettingResult.Deputies) {
        userSettingResult.Deputies = []
      }

      if (userSettingResult.LastVisit) {
        userSettingResult.OrgLastVisit = userSettingResult.LastVisit
      }

      // Get profile picture
      let graphToken = ''
      if (Config.LOCAL_DEVELOPMENT.toString() === 'false') {
        graphToken = await getGraphAuthToken(aadInfo.instance, aadInfo.accounts)
      }

      let pictureUrl = ''
      if (userUpn && Config.LOCAL_DEVELOPMENT.toString() === 'false') {
        const graphAPIUrl = `${Config.APIM_BASE_URL}msgraphapi/getprofilepicture?upn=${userUpn}`

        const pictureResponse = await fetch(graphAPIUrl, {
          method: 'GET',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
            Authorization: `Bearer ${graphToken}`,
            JWTToken: esToken
          }
        })

        if (pictureResponse && pictureResponse.ok) {
          const pictureBlob = await pictureResponse.blob()
          pictureUrl = URL.createObjectURL(pictureBlob)
        }
      }

      dispatch(fetchUserSettingsSuccess(userSettingResult, pictureUrl))
    } catch (error) {
      dispatch(fetchUserSettingsFailure())
      trackException('Error in fetching user settings action', error)
    }
  }
}

export const upSertUserSettings = (
  userSettingsRef: IUserSetting,
  deputiesChanged = false,
  deputiesAdded?: IDeputy[],
  deputiesRemoved?: IDeputy[]
  // eslint-disable-next-line @typescript-eslint/ban-types
): ThunkAction<Promise<void>, Store, {}, AnyAction> => {
  return async (
    // eslint-disable-next-line @typescript-eslint/ban-types
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => Store
  ) => {
    const baseEvent: ISelfServiceDeputyUpdateEvent = {
      action: 'UpdateDeputies',
      addedDeputies: deputiesAdded ? deputiesAdded.length : 0,
      removedDeputies: deputiesRemoved ? deputiesRemoved.length : 0
    }

    if (deputiesChanged) {
      trackSelfServiceEvent(baseEvent)
    }

    try {
      const userSettings = Object.assign({}, userSettingsRef)
      const state = getState()
      const initialLoaded = state.userSettings.initialLoaded
      const upn = state.userSettings.upn

      if (userSettings.OrgLastVisit) {
        delete userSettings.OrgLastVisit
      }

      // In case user settings are not fetched initially or the local settings are used,
      // don't save them back to cosmos db
      // (in case local settings are enabled, store changes in local storage)
      if (!initialLoaded) {
        trackException(
          'Error in upsert user settings',
          new Error('UserSettings not loaded skip saving to database')
        )

        dispatch(UpSertSetUserSettingsSuccess(userSettings))
        return
      }

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        if (deputiesChanged) {
          trackSelfServiceEvent({
            ...baseEvent,
            hasError: true,
            exception: 'Access token is empty'
          })
        }
        return
      }

      const apiUrl = `${Config.APIM_BASE_URL}usersettingsapi/upsertusersettings?upn=${upn}&scope=ESSettings`

      const data = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        },
        body: JSON.stringify(userSettings)
      })

      if (data.status !== 200 && data.status !== 201) {
        if (deputiesChanged) {
          trackSelfServiceEvent({
            ...baseEvent,
            hasError: true,
            exception: `Error updating the deputies in usersettings ${data.status}`
          })
        }

        trackException(
          'Error in upsert user settings',
          new Error('Error in data object after upsert user settings')
        )
        return
      }

      const result = await data.json()

      if (deputiesChanged) {
        trackSelfServiceEvent({
          ...baseEvent,
          additionalInfo: {
            action: 'GetMyRequests'
          }
        })

        // Get all user requests
        const apiUrl = `${Config.APIM_BASE_URL}selfserviceapi/getmyrequests?upn=${state.userSettings.upn}`

        const initialResponse = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
            Authorization: `Bearer ${esToken}`
          }
        })

        if (initialResponse && initialResponse.ok) {
          const response = await initialResponse.json()
          if (response && response.Documents) {
            const requestsToUpdate = response.Documents.filter(
              (request: ISelfServiceRequest) =>
                request.upn === state.userSettings.upn
            )

            trackSelfServiceEvent({
              ...baseEvent,
              hasError: false,
              additionalInfo: {
                action: 'GetMyRequests',
                requestsToUpdate: requestsToUpdate.length
              }
            })

            const requestsToUseForDeputyMails = requestsToUpdate
              .filter(
                (r: ISelfServiceRequest) =>
                  r.status === RequestStatus.Clarification ||
                  r.status === RequestStatus.Draft ||
                  r.status === RequestStatus.Submitted
              )
              .map((r: ISelfServiceRequest) => {
                return {
                  id: r.id,
                  upn: r.upn,
                  title: r.title,
                  status: r.status,
                  requestType: r.requestType
                }
              })
              .splice(0, 10)

            const deputiesMails = [
              ...(deputiesAdded
                ? deputiesAdded.map((d) => {
                    return {
                      deputy: d,
                      type: 'added',
                      requests: requestsToUseForDeputyMails
                    }
                  })
                : []),
              ...(deputiesRemoved
                ? deputiesRemoved.map((d) => {
                    return { deputy: d, type: 'removed' }
                  })
                : [])
            ]

            try {
              deputiesMails.forEach(async (entry) => {
                // Get all user requests
                const apiUrl = `${Config.APIM_BASE_URL}selfserviceapi/postdeputynotification?upn=${state.userSettings.upn}&displayname=${state.userSettings.displayName}`

                const utf8Bytes = encodeURIComponent(
                  JSON.stringify(entry)
                ).replace(/%([0-9A-F]{2})/g, function (match, p1) {
                  return String.fromCharCode(Number('0x' + p1))
                })

                const deputyResponse = await fetch(apiUrl, {
                  method: 'POST',
                  headers: {
                    accept: 'application/json',
                    'content-type': 'application/json',
                    'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
                    Authorization: `Bearer ${esToken}`
                  },
                  body:
                    Config.LOCAL_DEVELOPMENT.toString() === 'true'
                      ? JSON.stringify({
                          content: entry
                        })
                      : JSON.stringify({
                          content: `${btoa(utf8Bytes).replace(/=/g, '{eq}')}`
                        })
                })

                if (!deputyResponse || !deputyResponse.ok) {
                  trackException(
                    'Error in generating deputy notification',
                    new Error('Error in generating deputy notification')
                  )
                }
              })
            } catch (error) {
              trackException('Error in generating deputy notification', error)
            }
          } else {
            trackSelfServiceEvent({
              ...baseEvent,
              hasError: true,
              exception: 'Error in getting reference request: No documents',
              additionalInfo: {
                action: 'GetMyRequests'
              }
            })
          }
        } else {
          trackSelfServiceEvent({
            ...baseEvent,
            hasError: true,
            exception: `Error in getting reference request: ${initialResponse.status}`,
            additionalInfo: {
              action: 'GetMyRequests'
            }
          })
        }
      }

      // make sure the deputies get not changed with cryptified values
      result.Deputies = userSettings.Deputies ? userSettings.Deputies : []

      if (deputiesChanged) {
        trackSelfServiceEvent({
          ...baseEvent,
          hasError: false
        })
      }

      dispatch(UpSertSetUserSettingsSuccess(result))
    } catch (error) {
      if (deputiesChanged) {
        trackSelfServiceEvent({
          ...baseEvent,
          hasError: true,
          exception: `Unexpected error updating the deputies in usersettings`
        })
      }
      trackException('Error in upsert user settings action', error)
    }
  }
}

export type UserSettingsActions =
  | IFetchUserSettingsRequest
  | IFetchUserSettingsFailure
  | IFetchUserSettingsSuccess
  | IFetchUserSettingsNotFound
  | IUpsertUserSettingsSuccess
