import { AdsResult } from 'models/AdsResult'
import { ContactUsTile } from 'models/ContactUsTile'
import { FeaturedResult } from 'models/FeaturedResult'
import { PopupResult } from 'models/PopupResult'

export const parseAddsDataToExport = (adsList: AdsResult[]): AdsResult[] => {
  adsList.forEach((ad: AdsResult) => {
    if (ad && ad.text) ad.text = ad.text.replaceAll('\n', '')
  })
  return adsList
}

export const parsePopupsDataToExport = (
  popupList: PopupResult[]
): PopupResult[] => {
  popupList.forEach((popup: PopupResult) => {
    if (popup && popup.content)
      popup.content = popup.content.replaceAll('\n', '')
  })
  return popupList
}

export const parseFeaturedResultsDataToExport = (
  featuredResultsList: FeaturedResult[]
): FeaturedResult[] => {
  featuredResultsList.forEach((featuredResult: FeaturedResult) => {
    if (featuredResult && featuredResult.BestBetDescription)
      featuredResult.BestBetDescription =
        featuredResult.BestBetDescription.replaceAll('\n', '')
  })
  return featuredResultsList
}

export const parseContactUsTilesDataToExport = (
  contactUsTilesList: ContactUsTile[]
): ContactUsTile[] => {
  contactUsTilesList.forEach((contactUsTile: ContactUsTile) => {
    if (contactUsTile && contactUsTile.description)
      contactUsTile.description = contactUsTile.description.replaceAll('\n', '')
  })
  return contactUsTilesList
}
