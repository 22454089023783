export interface ContactUsTile {
  id: string
  language: string
  title: string
  description: string
  link: string
  image: string
  isActive: boolean
  order: number
  scope: 'OI' | 'KPMGFind' | 'All'
  draft?: boolean
}

export const initialContactUsTile: ContactUsTile = {
  id: '',
  language: 'en',
  title: '',
  description: '',
  link: '',
  image: '',
  isActive: true,
  order: 1,
  scope: 'All'
}
