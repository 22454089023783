import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getStylesAdminForm } from 'styles/admin/AdminForm'

import ConfirmationModal from 'components/contents/common/Dialog'
import EditFormTranslations from '../translations/EditFormTranslations'
import EditFormFeaturedResults from '../featuredResults/EditFormFeaturedResults'
import Popup from 'components/contents/Popup'
import { TranslationResult } from 'models/TranslationResult'
import { FeaturedResult } from 'models/FeaturedResult'
import { AdsResult } from '../../../models/AdsResult'
import { PopupDisplayTypes, PopupResult } from 'models/PopupResult'
import { getPopupDateString } from 'utils/Popups'
import { hasDateExpired } from 'utils/date'
import { AdminItemChange } from 'models/AdminItemChange'
import { searchFeaturedResultsDuplications } from 'utils/admin/adminSettingsUtils'
import Ads from 'components/contents/resultpages/Ads'
import { FormattedMessage } from 'react-intl'
import { adminSettingTypes } from 'constants/adminSettingTypes'
import { ContactUsTile } from 'models/ContactUsTile'
import EditFormContactUsTiles from '../contactustiles/EditFormContactUsTiles'
import EditFormAds from '../adwords/EditFormAds'
import EditFormPopups from '../popups/EditFormPopups'
import EditFormDidYouMean from '../didyoumean/EditFormDidYouMean'
import { DidYouMeanItem } from 'models/DidYouMean'
import EditFormSubscription from '../subscriptions/EditFormSubscription'
import { INewsLetterSubscription } from 'models/NewsLetterSubsciption'

export interface IEditFormBaseProps {
  rowData:
    | TranslationResult
    | FeaturedResult
    | (AdsResult | PopupResult | ContactUsTile | DidYouMeanItem)[]
    | INewsLetterSubscription
    | null
  isOpen: boolean
  isNewItem: boolean
  handleClose: () => void
  publishItem: (item: any) => void
  handleRowUpdate: (items: any, skipDisableOldOncePerUser?: boolean) => void
  adminSettingType: adminSettingTypes
  allAds?: AdsResult[]
  allContactUsTiles?: ContactUsTile[]
  changes: AdminItemChange[]
  allFeaturedResults?: FeaturedResult[]
}

export default function EditFormBase(props: IEditFormBaseProps): JSX.Element {
  const {
    rowData,
    isOpen,
    isNewItem,
    handleClose,
    handleRowUpdate,
    publishItem,
    adminSettingType,
    allAds,
    allContactUsTiles,
    changes,
    allFeaturedResults
  } = props

  const [isItemGoLiveModalVisible, setIsItemGoLiveModalVisible] =
    useState(false)
  const [isValid, setIsValid] = useState(false)
  const [showPopupPreview, setShowPopupPreview] = useState(false)
  const [showAdsPreview, setShowAdsPreview] = useState(false)
  const [referenceRowData, setRefereneRowData] = useState<
    | (AdsResult | PopupResult | ContactUsTile | DidYouMeanItem)[]
    | FeaturedResult
    | TranslationResult
    | INewsLetterSubscription
  >([])
  const [tabIndex, setTabIndex] = React.useState(0)

  const [changedTerms, setChangedTerms] = useState<string[]>(
    rowData &&
      (adminSettingType === adminSettingTypes.ads ||
        adminSettingType === adminSettingTypes.popups ||
        adminSettingType === adminSettingTypes.contactustiles)
      ? ((rowData as (AdsResult | PopupResult | ContactUsTile)[])
          .map((_row: AdsResult | PopupResult | ContactUsTile) => {
            if (
              changes.find(
                (_change: AdminItemChange) =>
                  (_change.changes as AdsResult | PopupResult | ContactUsTile)
                    .id === _row.id &&
                  (_change.changes as AdsResult | PopupResult | ContactUsTile)
                    .language === _row.language
              )
            ) {
              return _row.language
            }
            return undefined
          })
          .filter((language: string | undefined) => !!language) as string[])
      : adminSettingType === adminSettingTypes.didyoumean
      ? ((rowData as DidYouMeanItem[])
          .map((_row: DidYouMeanItem) => {
            if (
              changes.find(
                (_change: AdminItemChange) =>
                  (_change.changes as DidYouMeanItem).id === _row.id
              )
            ) {
              return _row.term
            }
            return undefined
          })
          .filter((corrected: string | undefined) => !!corrected) as string[])
      : []
  )
  const classes = getStylesAdminForm()
  const [forceRankValue, setForceRankValue] = useState(0)
  const [showRankChanges, setShowRankChanges] = useState(false)

  useEffect(() => {
    const deepCopy = JSON.parse(JSON.stringify(rowData ? rowData : []))
    setRefereneRowData(deepCopy)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const saveItem = (
    skipDisableOldOncePerUser: boolean,
    publishSave?: boolean
  ):
    | (AdsResult | PopupResult | ContactUsTile | DidYouMeanItem)[]
    | boolean
    | undefined => {
    if (!isValid) {
      return false
    }

    switch (adminSettingType) {
      case adminSettingTypes.translations:
      case adminSettingTypes.oitranslations:
      case adminSettingTypes.settingstranslations:
        saveTranslations(referenceRowData as TranslationResult)
        break
      case adminSettingTypes.featuredresults:
        if (handleDuplications(referenceRowData as FeaturedResult)) {
          const featuredResult = referenceRowData as FeaturedResult
          if (featuredResult && featuredResult.BestBetCountry.length === 0)
            featuredResult.BestBetCountry = ['All']
          if (featuredResult && featuredResult.BestBetCity.length === 0)
            featuredResult.BestBetCity = ['All']
          if (featuredResult && featuredResult.BestBetFunction.length === 0)
            featuredResult.BestBetFunction = ['All']
          if (featuredResult && featuredResult.BestBetDataSources.length === 0)
            featuredResult.BestBetDataSources = ['All']

          handleRowUpdate(featuredResult)
          return true
        }
        return false
      case adminSettingTypes.ads:
        return saveAds(referenceRowData as AdsResult[])
      case adminSettingTypes.popups:
        const items = referenceRowData as PopupResult[]
        if (
          publishSave &&
          items &&
          items.length > 0 &&
          items[0] &&
          items[0].isNewItem
        ) {
          delete items[0].isNewItem
        }
        return savePopups(
          referenceRowData as PopupResult[],
          skipDisableOldOncePerUser
        )
      case adminSettingTypes.contactustiles:
        return saveContactUsTiles(referenceRowData as ContactUsTile[])
      case adminSettingTypes.didyoumean:
        return saveDidYouMeanItems(referenceRowData as DidYouMeanItem[])
      case adminSettingTypes.newslettersubscriptions:
        handleRowUpdate(referenceRowData as INewsLetterSubscription)
        return true
    }
  }

  const saveTranslations = (transItem: TranslationResult) => {
    const enTranslationValue = transItem.translations.find(
      (t) => t.language === 'en'
    )?.value

    transItem.translations = transItem.translations.map((t) => {
      if (t.value) {
        return t
      } else {
        t.value = enTranslationValue ? enTranslationValue : ''
        return t
      }
    })

    handleRowUpdate(transItem)
  }

  const saveAds = (adsItems: AdsResult[]): AdsResult[] => {
    const editItems: AdsResult[] = []
    adsItems.forEach((item: AdsResult) => {
      if (changedTerms.find((cL: string) => cL === item.language)) {
        editItems.push({
          ...adsItems[0],
          ...{
            language: item.language,
            title: item.title,
            text: item.text,
            link_text: item.link_text,
            countries:
              item.countries.length === 0 ? ['All'] : adsItems[0].countries,
            functions:
              item.functions.length === 0 ? ['All'] : adsItems[0].functions
          }
        })
      }
    })

    handleRowUpdate(editItems)
    return editItems
  }

  const saveContactUsTiles = (cusItems: ContactUsTile[]): ContactUsTile[] => {
    const editItems: ContactUsTile[] = []
    cusItems.forEach((item: ContactUsTile) => {
      if (changedTerms.find((cL: string) => cL === item.language)) {
        editItems.push({
          ...cusItems[0],
          ...{
            language: item.language,
            title: item.title,
            description: item.description
          }
        })
      }
    })

    handleRowUpdate(editItems)
    return editItems
  }

  const saveDidYouMeanItems = (
    dymItems: DidYouMeanItem[]
  ): DidYouMeanItem[] => {
    const editItems: DidYouMeanItem[] = []
    dymItems.forEach((item: DidYouMeanItem) => {
      if (changedTerms.length > 0) {
        editItems.push({
          ...dymItems[0],
          ...{
            term: item.term
          }
        })
      }
    })
    if (editItems && editItems.length > 0) {
      handleRowUpdate(editItems)
    } else {
      handleRowUpdate(dymItems)
    }
    return editItems
  }

  const savePopups = (
    popupItems: PopupResult[],
    skipDisableOldOncePerUser: boolean
  ): PopupResult[] => {
    if (popupItems[0].display === PopupDisplayTypes.oncePerUser) {
      const today = new Date()
      popupItems[0].dateStart = getPopupDateString(today)
      popupItems[0].dateEnd = null
    }

    const editItems: PopupResult[] = []
    popupItems.forEach((item: PopupResult) => {
      if (changedTerms.find((cL: string) => cL === item.language)) {
        editItems.push({
          ...popupItems[0],
          ...{
            language: item.language,
            title: item.title,
            content: item.content,
            button_text: item.button_text
          }
        })
      }
    })

    handleRowUpdate(editItems, skipDisableOldOncePerUser)
    return editItems
  }

  const getModalConfirmMessage = (): string => {
    switch (adminSettingType) {
      case adminSettingTypes.translations:
      case adminSettingTypes.oitranslations:
      case adminSettingTypes.settingstranslations:
        return `Are you shure to publish current translation changes?`
      default:
        return 'Are you shure to publish current changes?'
    }
  }

  const getDialogTitle = (): string => {
    switch (adminSettingType) {
      case adminSettingTypes.translations:
      case adminSettingTypes.oitranslations:
      case adminSettingTypes.settingstranslations:
        return rowData && (rowData as TranslationResult).key
          ? `Edit Translation '${(rowData as TranslationResult).key}'`
          : 'New Translation'
      case adminSettingTypes.ads:
        return isNewItem ? 'New Adword' : 'Edit Adword'
      case adminSettingTypes.contactustiles:
        return isNewItem ? 'New Contact Us Tile' : 'Edit Contact Us Tile'
      case adminSettingTypes.didyoumean:
        return isNewItem ? 'New Did You Mean Item' : 'Edit Did You Mean Item'
      case adminSettingTypes.popups:
        return isNewItem ? 'New Popup' : 'Edit Popup'
      case adminSettingTypes.newslettersubscriptions:
        return isNewItem ? 'New Subscription' : 'Edit Subscription'
      default:
        return rowData ? 'Edit Item' : 'New Item'
    }
  }

  const handleDuplications = (featuredResult: FeaturedResult) => {
    const rank = featuredResult.BestBetRank ? featuredResult.BestBetRank : 0

    // Only do the validation once, so the user is able to change the rank afterwards
    // without any validation happening
    if (forceRankValue > 0) {
      return true
    }

    //db list
    let highestRank = searchFeaturedResultsDuplications(
      featuredResult,
      allFeaturedResults ? allFeaturedResults : []
    )

    //change list
    const highestRankChanges = searchFeaturedResultsDuplications(
      featuredResult,
      changes.map(
        (fResultChange: AdminItemChange) =>
          fResultChange.changes as FeaturedResult
      )
    )
    if (highestRankChanges !== -1 && highestRankChanges > highestRank)
      highestRank = highestRankChanges

    if (highestRank !== -1) {
      if (rank < highestRank) {
        setForceRankValue(highestRank)
        setIsItemGoLiveModalVisible(false)
        setShowRankChanges(true)
        return false
      }
    }

    return true
  }

  const getDialogContent = () => {
    switch (adminSettingType) {
      case adminSettingTypes.translations:
      case adminSettingTypes.oitranslations:
      case adminSettingTypes.settingstranslations:
        return (
          <EditFormTranslations
            rowData={referenceRowData as TranslationResult}
            isNewItem={isNewItem}
            setIsValid={setIsValid}
            isOiTranslation={
              adminSettingType === adminSettingTypes.oitranslations
            }
          />
        )
      case adminSettingTypes.featuredresults:
        return (
          <EditFormFeaturedResults
            rowData={referenceRowData as FeaturedResult}
            isNewItem={isNewItem}
            setIsValid={setIsValid}
            forceRank={forceRankValue}
          />
        )
      case adminSettingTypes.ads:
        return (
          <EditFormAds
            itemList={referenceRowData as AdsResult[]}
            isNewItem={isNewItem}
            setIsValid={setIsValid}
            changedLanguages={changedTerms}
            setChangedLanguages={setChangedTerms}
            setItemList={setRefereneRowData}
            allAds={allAds ? allAds : []}
            changes={changes}
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
          />
        )
      case adminSettingTypes.popups:
        return (
          <EditFormPopups
            itemList={referenceRowData as PopupResult[]}
            isNewItem={isNewItem}
            setIsValid={setIsValid}
            changedLanguages={changedTerms}
            setChangedLanguages={setChangedTerms}
            setItemList={setRefereneRowData}
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
          />
        )
      case adminSettingTypes.contactustiles:
        return (
          <EditFormContactUsTiles
            itemList={referenceRowData as ContactUsTile[]}
            isNewItem={isNewItem}
            setIsValid={setIsValid}
            changedLanguages={changedTerms}
            setChangedLanguages={setChangedTerms}
            setItemList={setRefereneRowData}
            allContactUsTiles={allContactUsTiles ? allContactUsTiles : []}
          />
        )
      case adminSettingTypes.didyoumean:
        return (
          <EditFormDidYouMean
            items={referenceRowData as DidYouMeanItem[]}
            setIsValid={setIsValid}
          />
        )
      case adminSettingTypes.newslettersubscriptions:
        return (
          <EditFormSubscription
            item={referenceRowData as INewsLetterSubscription}
            setIsValid={setIsValid}
          />
        )
      default:
        return <></>
    }
  }

  return (
    <>
      <Dialog
        open={isOpen && rowData !== null}
        onClose={(event: object, reason: string) => {
          if (reason !== 'backdropClick') handleClose()
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="confirmation-modal-title">
          {getDialogTitle()}
        </DialogTitle>
        <DialogContent className={classes.dialogContentContainer}>
          {getDialogContent()}
        </DialogContent>
        <DialogActions>
          {(adminSettingTypes.popups === adminSettingType ||
            adminSettingTypes.ads === adminSettingType) && (
            <Button
              color="secondary"
              onClick={() => {
                if (adminSettingTypes.popups === adminSettingType)
                  setShowPopupPreview(true)
                if (adminSettingTypes.ads === adminSettingType)
                  setShowAdsPreview(true)
              }}
            >
              Preview
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setIsItemGoLiveModalVisible(true)
            }}
            className={classes.button}
            disabled={
              !!(
                !isValid ||
                (adminSettingType === adminSettingTypes.popups &&
                  (referenceRowData as PopupResult[])[0].display ===
                    'oncePerUser' &&
                  (referenceRowData as PopupResult[])[0].dateEnd &&
                  hasDateExpired(
                    (referenceRowData as PopupResult[])[0].dateEnd
                  ))
              )
            }
          >
            Go Live!
          </Button>
          <Button
            color="primary"
            autoFocus
            onClick={() => {
              saveItem(false)
            }}
            disabled={
              !!(
                !isValid ||
                (adminSettingType === adminSettingTypes.popups &&
                  (referenceRowData as PopupResult[])[0].display ===
                    'oncePerUser' &&
                  (referenceRowData as PopupResult[])[0].dateEnd &&
                  hasDateExpired(
                    (referenceRowData as PopupResult[])[0].dateEnd
                  ))
              )
            }
          >
            Save
          </Button>
          <Button color="primary" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {adminSettingTypes.popups === adminSettingType && (
        <Popup
          popupResult={
            (referenceRowData as PopupResult[])[
              (referenceRowData as PopupResult[]).length > tabIndex + 1
                ? tabIndex
                : 0
            ]
          }
          open={showPopupPreview}
          onClose={() => {
            setShowPopupPreview(false)
          }}
        />
      )}
      {adminSettingTypes.ads === adminSettingType && showAdsPreview && (
        <Dialog
          open={showAdsPreview}
          onClose={handleClose}
          aria-labelledby="confirmation-modal"
          aria-describedby="confirmation-modal-description"
        >
          <DialogContent style={{ marginLeft: '-16px' }}>
            <Ads
              adItem={
                (referenceRowData as AdsResult[])[
                  (referenceRowData as AdsResult[]).length > tabIndex + 1
                    ? tabIndex
                    : 0
                ]
              }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowAdsPreview(false)} color="primary">
              <FormattedMessage id="close" defaultMessage="Close" />
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <ConfirmationModal
        handleConfirm={() => {
          const saveItemResult = saveItem(true, true)

          if (typeof saveItemResult === 'boolean') {
            if (saveItemResult) {
              publishItem(referenceRowData)
            } else {
            }
          } else {
            if (saveItemResult && saveItemResult.length > 0)
              publishItem(saveItemResult)
            else publishItem(referenceRowData)
          }
        }}
        handleClose={() => {
          setIsItemGoLiveModalVisible(false)
        }}
        isModalOpen={isItemGoLiveModalVisible}
        title={getModalConfirmMessage()}
        message={
          'Changes will be immediately deployed and will be visible to all KPMG users'
        }
        hideCancelButton={false}
        confirmBtnText={'Yes'}
        cancelBtnText={'No'}
      />
      <ConfirmationModal
        handleConfirm={() => {
          setShowRankChanges(false)
        }}
        isModalOpen={showRankChanges}
        title={'Rank value has been changed'}
        message={'The rank value was changed automatically'}
        hideCancelButton={true}
        confirmBtnText={'ok'}
        cancelBtnText={''}
      />
    </>
  )
}
