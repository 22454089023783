export enum adminSettingTypes {
  ads = 'Translations-Ads',
  popups = 'Translations-Popups',
  translations = 'Translations-Global',
  oitranslations = 'Translations-Global-OI',
  featuredresults = 'Featured-Results',
  contactustiles = 'ContactUs-Tiles',
  didyoumean = 'DidYouMean',
  settingstranslations = 'Translations-Settings',
  newslettersubscriptions = 'NewsLetterSubscriptions'
}

export type adminScopes = 'OI' | 'KPMGFind' | 'All'
export type adFlavour = 'small' | 'medium' | 'large' | undefined
